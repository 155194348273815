// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
    _21: ImageType,
    _22: ImageType,
    _23: ImageType,
    _24: ImageType,
    _25: ImageType,
    _26: ImageType,
    _27: ImageType,
    _28: ImageType,
    _29: ImageType,
    _30: ImageType,
    _31: ImageType,
    _32: ImageType,
    _33: ImageType,
    _34: ImageType,
    _35: ImageType,
    _36: ImageType,
    _37: ImageType,
    _38: ImageType,
    _39: ImageType,
    _40: ImageType,
    _41: ImageType,
    _42: ImageType,
    _43: ImageType,
    _44: ImageType,
    _45: ImageType,
    _46: ImageType,
    _47: ImageType,
    _48: ImageType,
    _49: ImageType,
    _50: ImageType,
    _51: ImageType,
    _52: ImageType,
    _53: ImageType,
    _54: ImageType,
    _55: ImageType,
    _56: ImageType,
    _57: ImageType,
    _58: ImageType,
    _59: ImageType,
    _60: ImageType,
    _61: ImageType,
    _62: ImageType,
    _63: ImageType,
    _64: ImageType,
    _65: ImageType,
    _66: ImageType,
    _67: ImageType,
  },
};

class Blog_2018_04_19_Halls_Creek extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>Two years after bailing out, I finally got another crack at Poe.</p>
        <h2>Apr 19 - Hike to Hall's Creek</h2>
        <p>
          We met up and drove down to the shortcut trailhead, arriving shortly
          before dark. It was a long, windy, and difficult hike with our 60 lb
          packs and only a gps and headlamps to guide our way. However, the
          temperature was just right and we were all excited to make it to
          Miller's Creek. We made it to the cliff without much difficulty.
        </p>
        <p>
          Once at the cliff, we struggled to find the correct way down. There
          was one point where we lowered our packs so we could make a
          low-ceiling crawl to a down-climbable spot. And we spent some time
          looking for a way down to the next cliff band. I estimate we spent an
          additional 45 minutes with those two detours that we could have
          avoided had it been light outside. Not bad. We arrived at Miller's
          shortly before midnight.
        </p>
        <h2>Apr 20 - Miller's and Baboon Laughs</h2>
        <h3>Miller's in the rain</h3>
        <p>
          We awoke early to a gray skies and a soft rain. We had breakfast and
          packed for Baboon Laughs, ignoring the elephant-in-the-room that was
          the rain and high chance the canyon was flooding right then. Instead
          of sitting around, we chose to hike up Miller's Creek. Miller's is a
          non-technical canyon that cuts through the{' '}
          <GlossaryLink>sandstone</GlossaryLink> ramp that makes up the western
          border of Hall's Creek.
        </p>
        <p>
          We went up a mile or so, walked up the side to an alcove that
          sheltered us from the storm, and sat there for an hour talking. Then
          we went back to camp. Each hour that passed the rain fell harder and
          harder. Hiking down we saw more and more waterfalls appear.
        </p>
        <Image
          caption={
            <>
              Entering Miller's Creek <GlossaryLink>narrows</GlossaryLink>
            </>
          }
          image={data._1}
        />
        <Image caption="Desert columbine" image={data._2} />
        <Image
          caption="Sampling the sweet nectar of Mother Nature"
          image={data._3}
        />
        <Image
          caption="Yep, this sucks - we want to be in the canyons right now"
          image={data._4}
        />
        <p>
          As the creek rose to it's highest point morale fell to its lowest. We
          were all soaked and we assumed we would just be sitting in the rain
          all weekend instead of doing canyons. At camp we chose to nap for a
          few hours until the rain cleared out.
        </p>
        <Image caption="Miller's Creek" image={data._5} />
        <Image caption="Hiking in the rain" image={data._6} />
        <h3>Baboon Laughs</h3>
        <CanyonRatingCard rating="4BIII" title="Baboon Laughs" />
        <p>
          Early afternoon the rain finally subsided, the clouds cleared out, and
          we had just enough time to go and attempt Baboon Laughs. We repacked,
          grabbed our headlamps, and headed up the ramp.
        </p>
        <Image
          caption="Hall's Creek on approach to Baboon Laughs"
          image={data._7}
        />
        <p>
          Early on I found myself struggling to keep up with the rest of the
          group. I would get tired and need a break or a drink so I'd stop and
          the rest of the group would continue. This has never happened to me
          before and it was a truly miserable experience. I know nobody else
          cared because we were moving quickly and had a short approach. I'd
          would be able to make up the time putting on my wetsuit or at the
          first rappel. Still, it sucked being the slow person.
        </p>
        <Image
          caption={
            <>
              Top of Baboon Laughs <GlossaryLink>drainage</GlossaryLink>
            </>
          }
          image={data._8}
        />
        <Image caption="Lizard" image={data._9} />
        <p>
          We <GlossaryLink id="down-climb">down climbed</GlossaryLink> the first
          rappel and rigged the second while getting wetsuits on. At that point
          in time I started feeling nauseous. I don't know if it was
          dehydration, anxiety, or a lingering sickness I had struggled with a
          few weeks ago, but I did not feel good at all. What a dilemma. We
          hiked, camped, did the approach, and I was in my wetsuit ready to go
          and suddenly I started feeling sick? What's that fallacy called where
          you are so invested in something you choose to continue with it even
          though it might not be a good idea? Yep, I chose to continue. Turned
          out to be the right choice.
        </p>
        <p>The canyon was in "easy" mode thanks to the rain.</p>
        <Image caption="First swim" image={data._10} />
        <Image
          caption={
            <>
              <GlossaryLink>Partner assist</GlossaryLink> down a{' '}
              <GlossaryLink>chockstone</GlossaryLink>
            </>
          }
          image={data._11}
        />
        <Image image={data._12} />
        <p>
          The <GlossaryLink>potholes</GlossaryLink> were all full and flowing so
          it was lots of easy swimming, down climbs, jumping some of the
          rappels, etc.
        </p>
        <Image caption="Poison Ivy" image={data._13} />
        <Image image={data._14} />
        <Image image={data._15} />
        <Image image={data._16} />
        <Image caption="Kevin on rappel" image={data._17} />
        <Image
          caption="Anthony and Mark getting ready to jump the rappel"
          image={data._18}
        />
        <Image caption="Slide into water" image={data._19} />
        <p>
          Overall it was refreshing to get into a canyon after a day full of
          rain.
        </p>
        <Image image={data._20} />
        <Image image={data._21} />
        <p>
          We shot through the canyon, making it back to camp with plenty of
          light for transitioning to dinner and bed. I went to be early hoping
          to get plenty of sleep for the big canyon the next day.
        </p>
        <h2>Apr 21 - Poe</h2>
        <CanyonRatingCard rating="4BIV" title="Poe" />
        <h3>Approach</h3>
        <p>
          We got up at dawn, ate breakfast, and headed out to Poe at 7:00. I
          normally spend the entire approach of difficult canyons worrying about
          what we are going to face that day. Because of the rain the day prior,
          I spent the approach worrying about the difficult obstacles we would
          be missing because everything was going to be full. We finished the
          approach, geared up, and rappelled down into the canyon.
        </p>
        <h3>In Canyon</h3>
        <p>
          Once in the canyon, I was surprised to see that the potholes were
          getting emptier as we went deeper.
        </p>
        <Image
          caption="Excellent down climb performed by Jeremy"
          image={data._22}
        />
        <Image
          caption="Handjam and as far down as he can go"
          image={data._23}
        />
        <Image caption="Finished the climb" image={data._24} />
        <p>
          We actually encountered only one swim in the entire canyon. How weird
          is it that Baboon Laughs is only a quarter of a mile away and was
          flowing but Poe appears to have received no precipitation. We were so
          lucky!
        </p>
        <Image
          caption="Dropping into one of the empty potholes"
          image={data._25}
        />
        <Image caption="On rappel" image={data._26} />
        <Image caption="Another pothole" image={data._27} />
        <Image
          caption={
            <>
              Rigging a <GlossaryLink>sand trap</GlossaryLink>
            </>
          }
          image={data._28}
        />
        <Image
          caption="I tried to take a picture of the brand but it didn't turn out"
          image={data._29}
        />
        <Image
          caption="Watching to see if it moves while weighted"
          image={data._30}
        />
        <h3>Optional Potholes</h3>
        <p>
          We quickly arrived at a series of potholes that are avoidable. In the
          beta it says if you get to them after noon you should skip them since
          you need to hurry. We arrived well before and chose to do them. We
          passed all three in just 15 minutes,{' '}
          <GlossaryLink id="partner-capture">partner capturing</GlossaryLink>{' '}
          down and{' '}
          <GlossaryLink id="partner-assist">partner assisting</GlossaryLink> up
          and out of each one.
        </p>
        <Image
          caption={
            <>
              Experimenting with the <GlossaryLink>water anchor</GlossaryLink> -
              this drop had poor geometry and so we just down climbed it
            </>
          }
          image={data._31}
        />
        <Image caption="Shuttling packs" image={data._32} />
        <Image
          caption="Finished the optional potholes and on our way"
          image={data._33}
        />
        <h3>Water Anchor</h3>
        <p>
          This was my first time to use a water anchor. We used an early version
          of the <GlossaryLink>W'Anchor</GlossaryLink> created by Steve Woodford
          and manufactured by{' '}
          <Link to="http://www.onropecanyoneering.com/">
            On Rope Canyoneering
          </Link>
          . I'll write more about it in a dedicated article later. Short story
          is that it worked really well and I was very excited to be{' '}
          <GlossaryLink>LAPAR</GlossaryLink> the first time down. After that
          rappel we stopped for lunch.
        </p>
        <Image caption="Filling the water anchor" image={data._34} />
        <Image caption="Set in place and ready to go" image={data._35} />
        <Image
          caption="Green is rappel rope, orange is pull. Green pinches orange so it won't accidentally pull the plug and release the water."
          image={data._36}
        />
        <h3>Pit of Despair</h3>
        <Image
          caption="Me getting ready to ascend out of the Pit of Despair"
          image={data._37}
        />
        <p>
          Shortly after noon we arrived at the Pit of Despair! We immediately
          got to work. Two of us filled <GlossaryLink>potshots</GlossaryLink>,
          one person rigged them to ropes, one person weighted the potshots and
          passed them up to the thrower, one person stemmed up to the throw
          position, and one person managed the ropes by coiling them and pulling
          missed potshots back.
        </p>
        <Image
          caption="Anthony and I near the Pit of Despair"
          image={data._38}
        />
        <Image
          caption="This is where all the throws will take place"
          image={data._39}
        />
        <Image
          caption="Looking high up at some bolts. This is an alternate way to get past the Pit"
          image={data._40}
        />
        <p>
          First throw, 1, 2, 3, miss! Second, 1, 2, 3, miss again! Third, 1, 2,
          3, success! Fourth success, fifth success! Anyone else want to try?
        </p>
        <h4>The Throw</h4>
        <p>
          I wasn't going to miss my chance to know if I could make the throw.
        </p>
        <p>
          I climbed up to height - not too hard. I got into position - it's
          awkward. The left wall is mostly vertical and the right wall has a bit
          of a slant. As you get farther forward, the right wall changes its
          angle and flares out and down into the pit.
        </p>
        <p>
          I'm 6' 1" and I found the best spot for me was to get my right foot on
          that flared out part. And I went as far forward as I could.
        </p>
        <p>
          Anthony <GlossaryLink id="chimneying">chimneyed</GlossaryLink> under
          me with his shoulder beneath my left foot. This was done in case I
          slipped - hopefully his shoulder would keep my foot from slipping too
          far. My foot was fully weighted on the wall and I actually wasn't
          touching Anthony at all.
        </p>
        <p>
          I tied a <GlossaryLink>butterfly loop</GlossaryLink> a few feet from
          the potshot and grabbed that loop when swinging. Thinking about it
          now, I might want my swing to be as long as possible. Don't know,
          though.
        </p>
        <p>
          The potshot was at the standard fill length for this throw - somewhere
          between 1/4 and 1/3 of the way full.
        </p>
        <p>
          I started swinging and once I got a rhythm I counted 3 swings and
          released on 3.
        </p>
        <p>
          I succeeded on the third try once I realized I needed to go as far
          forward as possible.
        </p>
        <p>
          I did not take my wetsuit off (I heard that helps) and I did not have
          a safety line tied to my harness.
        </p>
        <p>
          After making it I stepped down to give others a chance. Mark also
          tried, also made one over, and then stepped down.
        </p>
        <h4>New Throw Technique</h4>
        <p>
          We still had 2 potshots left and no one else wanted to give it a shot
          so Anthony suggested we try something he had been thinking about.
        </p>
        <p>
          This requires 2 people, the thrower and the pendulum. The pendulum
          gets into the standard throw position - up high and far forward.
        </p>
        <p>
          The thrower is chimneying below and behind the pendulum with their
          back against the left wall. In this position it is easiest for the
          thrower to aim the potshot right at the destination drop.
        </p>
        <p>
          Maximizing the distance between the pendulum and thrower is crucial to
          getting a good throw. The thrower needs to be able to throw the
          potshot in the correct direction without it hitting any walls on the
          way there. But make it as long as possible otherwise.
        </p>
        <p>
          Because the we need to maximize this distance, having someone below
          the pendulum spotting their foot like Anthony did for me is
          impossible. The pendulum has to be able to get up high and stay up
          there.
        </p>
        <p>
          Once the two people are in position, the thrower holds the potshot and
          the pendulum holds the rope, tying a loop if it helps them grip the
          rope.
        </p>
        <p>
          The thrower then counts to 3 and throws the potshot down as hard as
          they can. This is the same as pushing a child swing as hard as you can
          to try and wrap the swing around the top bar.
        </p>
        <p>
          At the same time the thrower throws, the pendulum swings their arms up
          as if they are throwing by themselves and releases.
        </p>
        <p>
          The thought behind all of this is that the pendulum has much more
          force swinging the potshot then they could ever get by themselves.
        </p>
        <p>
          On our first attempt, I estimate the throw made it 12 feet beyond the
          lip.
        </p>
        <p>
          The second and final attempt we were closer together and the timing
          was a little off so we only made it 3 feet over the lip - still
          farther than any individual attempt.
        </p>
        <p>
          Jeremy did the climb. Since the pit was empty it was an easy start. He
          pulled each individual rope until it caught then{' '}
          <GlossaryLink id="batman-up">batman'ed</GlossaryLink> up all 7
          strands. Once up everyone else soon followed and we were on our way.
        </p>
        <Image
          caption="We used the W'anchor for the rappel into the Pit"
          image={data._41}
        />
        <Image caption="Jeremy after the climb" image={data._42} />
        <Image caption="Jeremy and Kevin pulling Mark out" image={data._43} />
        <h3>The Wart</h3>
        <p>
          Continuing on we made it to the next major pothole, The Wart. This one
          would demand just as much attention and preparation as the Pit of
          Despair if it weren't for a wart about the pothole. Someone climbs up
          to the wart, sets the rope, and rappels down to the other side.
        </p>
        <Image caption="Ken climbing up to the wart" image={data._44} />
        <Image
          caption="The wart pothole from the up canyon side"
          image={data._45}
        />
        <Image caption="Looking down into the wart hole" image={data._46} />
        <p>
          We hadn't put nearly enough thought into passing this obstacle so we
          kind of stumbled through it. We had the first and last people climb
          and rappel from the wart and set up a{' '}
          <GlossaryLink>Tyrolean traverse</GlossaryLink> for the rest. It worked
          fine but if we would have taken the time beforehand to discuss what to
          do it would have gone more smoothly.
        </p>
        <Image
          caption="Kevin traversing across the wart hole"
          image={data._47}
        />
        <h3>Exit</h3>
        <Image caption="Rigging the last rappel" image={data._48} />
        <Image caption="Last rappel" image={data._49} />
        <p>
          After the wart the most difficult challenges is still ahead of us.
          Poison Ivy! After the final rappel we tip toed over, under, and around
          it and climbed up and out of the canyon at the first chance to avoid
          it.
        </p>
        <p>It was then a short hike back to camp.</p>
        <h2>Apr 22 - Happy Dog</h2>
        <CanyonRatingCard rating="4BIIIR" title="Happy Dog" />
        <p>
          Happy dog is hands-down the best canyon of the three we all did this
          trip. Poe may have the greater challenges and bigger potholes, but
          Happy Dog wins with its beauty and how strenuous it was to complete. A
          trip to Hall's Creek is incomplete without a descent down this canyon.
        </p>
        <p>
          The approach is closer to camp but has the most elevation gain of the
          three canyons. Near the top, it has a spectacular view into Halls
          Creek. This was also the only place we could see Lake Powell, the
          Bears Ears Buttes, and Bullfrog.
        </p>
        <Image
          caption="Looking down into Miller's Creek from near the top of Happy Dog"
          image={data._50}
        />
        <Image image={data._51} />
        <Image image={data._52} />
        <Image image={data._53} />
        <p>
          Also near the top there is a really cool{' '}
          <GlossaryLink>arch</GlossaryLink> that is actually more like a
          hollowed out dome. Really cool!
        </p>
        <Image caption="Mark at the arch" image={data._54} />
        <Image caption="Jeremy at the arch" image={data._55} />
        <Image caption="Within Happy Dog Arch" image={data._56} />
        <p>
          Once in the canyon we were treated to lots of{' '}
          <GlossaryLink id="r-risk-rating">R-rated stemming</GlossaryLink> mixed
          with swim after swim after swim.
        </p>
        <Image
          caption={
            <>
              First <GlossaryLink>high-stemming</GlossaryLink> section
            </>
          }
          image={data._57}
        />
        <Image caption="First high-stemming section" image={data._58} />
        <Image caption="Jumping into a pool" image={data._59} />
        <Image caption="More high stemming?" image={data._60} />
        <Image
          caption="It opens up for a bit before more stemming and swimming"
          image={data._61}
        />
        <Image image={data._62} />
        <Image image={data._63} />
        <Image image={data._64} />
        <Image image={data._65} />
        <Image
          caption="Anthony taking a shower at the bottom of last rappel"
          image={data._66}
        />
        <Image caption="Alcove at bottom of last rappel" image={data._67} />
        <p>
          <Link to="http://canyoncollective.com/threads/new-throwing-technique-for-the-pit-of-despair-poe-canyon-and-others.25294/">
            See also Anthony's trip report on Canyon Collective
          </Link>
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-04-19-halls-creek')(
  Blog_2018_04_19_Halls_Creek
);

export const query = graphql`
  query {
    _1: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(relativePath: { eq: "images/blog/2018-04-19-halls-creek/9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/10.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/14.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/15.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/16.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/17.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _18: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/18.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _19: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/19.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _20: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/20.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _21: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/21.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _22: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/22.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _23: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/23.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _24: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/24.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _25: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/25.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _26: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/26.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _27: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/27.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _28: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/28.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _29: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/29.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _30: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/30.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _31: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/31.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _32: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/32.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _33: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/33.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _34: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/34.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _35: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/35.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _36: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/36.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _37: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/37.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _38: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/38.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _39: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/39.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _40: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/40.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _41: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/41.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _42: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/42.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _43: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/43.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _44: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/44.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _45: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/45.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _46: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/46.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _47: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/47.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _48: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/48.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _49: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/49.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _50: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/50.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _51: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/51.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _52: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/52.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _53: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/53.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _54: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/54.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _55: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/55.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _56: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/56.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _57: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/57.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _58: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/58.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _59: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/59.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _60: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/60.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _61: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/61.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _62: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/62.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _63: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/63.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _64: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/64.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _65: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/65.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _66: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/66.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _67: file(
      relativePath: { eq: "images/blog/2018-04-19-halls-creek/67.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
